/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import './src/styles/tailwind.css';
import './src/styles/global.css';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    // eslint-disable-next-line no-restricted-properties
    customEasing: (x) => (x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2 : (2 - Math.pow(2, -20 * x + 10)) / 2),
    offset: () => {
      if (document.querySelector('#anchor-menu:not(.menu--open)')) {
        return document.querySelector('#anchor-menu:not(.menu--open)').clientHeight;
      }

      return 68.89;
    },
    updateURL: false,
  });
}

export const wrapRootElement = ({ element }) => (
  element
);
